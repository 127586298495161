import { Application } from "@shared/constants";

export const getMainApplicant = (application: Application) =>
  application.applicants[application.mainApplicantId];

export const getCoApplicants = (application: Application) => {
  const mainApplicantId = application.mainApplicantId;

  return Object.values(application.applicants).filter(
    (applicant) => applicant.applicantId !== mainApplicantId
  );
};

export const getCoApplicantsByRole = (application: Application) => {
  const coApplicants = getCoApplicants(application);

  const guarantor = coApplicants.find((applicant) => applicant.guarantor);

  const nonGuarantorCoApplicants = coApplicants.filter(
    (applicant) => !applicant.guarantor
  );

  return { guarantor, nonGuarantorCoApplicants };
};
