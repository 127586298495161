import type { Application } from "@shared/constants";

export const APPLICATION_TYPE: Record<Application["type"], string> = {
  NEW: "new mortgage",
  RENEWAL: "renewal",
  REFINANCE: "refinance",
  // new Augmented Application Types
  PORT: "Port",
  PRE_APPROVAL: "pre approval",
  ASSUMPTION: "assumption",
  CHANGE_OF_COVENANT: "change of covenant",
  CONSTRUCTION_LOAN: "construction loan",
  READVANCE: "readvance",
  BRIDGE: "bridge",
  TRANSFER: "transfer",
  PURCHASE_WITH_IMPROVEMENT: "purchase with improvement",
} as const;
