import { ButtonIcon, Flex, useBreakpointValue } from "@nestoca/ui";
import clsx from "clsx";
import Link from "next/link";
import { BsCheck, BsFiles } from "react-icons/bs";
import useClipboard from "react-use-clipboard";

import styles from "./contact.module.scss";

import type { advisorOptionType } from "./contact";

export const ContactItem = ({
  optionItem,
  onClick,
  className,
}: {
  optionItem: advisorOptionType;
  onClick: (option: advisorOptionType) => void;
  className?: string;
}) => {
  const isMobile = useBreakpointValue({ default: true, md: false });

  const [isCopied, setCopied] = useClipboard(String(optionItem.data), {
    // `isCopied` will go back to `false` after 1000ms.
    successDuration: 1000,
  });

  return (
    <>
      {isMobile ? (
        <ContactLink optionItem={optionItem} />
      ) : (
        <Flex className={clsx(styles.option, className)} gap={3}>
          <Flex direction="column">
            <div className={styles["option-subheader"]}>{optionItem.name}</div>
            {optionItem.id === "email" ? (
              <a
                href={`mailto:${optionItem.data}`}
                className={styles["option-data"]}
              >
                {optionItem.data}
              </a>
            ) : (
              <div className={styles["option-data"]}>{optionItem.data}</div>
            )}
          </Flex>
          {optionItem.canCopy && (
            <ButtonIcon
              aria-label="copy"
              icon={
                isCopied ? (
                  <BsCheck color="var( --color-status-success-foreground)" />
                ) : (
                  <BsFiles color="black" />
                )
              }
              backgroundColor="transparent"
              size="small"
              className={styles.button}
              onClick={() => {
                setCopied();
                onClick(optionItem);
              }}
              data-dd-action-name={`copy-${optionItem.name}`}
            />
          )}
        </Flex>
      )}
    </>
  );
};

const ContactLink = ({ optionItem }: { optionItem: advisorOptionType }) => {
  if (optionItem.link) {
    return (
      <Link passHref href={optionItem.link} key={optionItem.id} legacyBehavior>
        <Flex
          as="a"
          align="center"
          justify="between"
          className={styles["option-wrapper"]}
          data-dd-action-name="contact link"
        >
          <Flex direction="column">
            <div className={styles["option-subheader"]}>{optionItem.name}</div>
            <div className={styles["option-data"]}>{optionItem.data}</div>
          </Flex>
          {optionItem.icon}
        </Flex>
      </Link>
    );
  }

  return (
    <Flex align="center" justify="between" className={styles["option-wrapper"]}>
      <Flex direction="column">
        <div className={styles["option-subheader"]}>{optionItem.name}</div>
        <div className={styles["option-data"]}>{optionItem.data}</div>
      </Flex>
      {optionItem.icon}
    </Flex>
  );
};
