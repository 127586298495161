import type { FC, PropsWithChildren } from "react";

import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { useTenant } from "@nestoca/multi-tenant";
import { useTenantConnection } from "@shared/utils";
import { useRouter } from "next/router";

import { config } from "./utils/tenant-connection-config";

export interface AuthProviderProps {
  rid?: string;
}

export const AuthProvider: FC<PropsWithChildren<AuthProviderProps>> = ({
  rid,
  children,
}) => {
  const tenant = useTenant();
  const { query, replace } = useRouter();
  const connection = useTenantConnection({ tenant, config });
  const applicationId = query.applicationId as string;
  const referenceNumber = query.referenceNumber as string;

  const onRedirectCallback = (appState?: AppState) => {
    replace(appState?.returnTo || "/");
  };

  if (!tenant || !tenant?.auth0) {
    return <>{children}</>;
  }

  return (
    <Auth0Provider
      cacheLocation="localstorage"
      domain={tenant.publicDomain || tenant.auth0.domain}
      clientId={tenant.auth0.clientId}
      useRefreshTokens
      useRefreshTokensFallback
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: `${tenant.auth0.redirectUri}/callback`,
        organization: tenant.auth0.organization || undefined,
        audience: tenant.auth0.audience || "https://nesto.ca/api",
        accountRid: rid,
        connection,
        // this enables the passwordless Auth0 login flow if the tenant has it enabled
        // this only works for the staging environment
        passwordless: true,
        applicationId,
        referenceNumber,
      }}
    >
      {children}
    </Auth0Provider>
  );
};
