import { useMemo } from "react";

import { Chip, Flex, Typography } from "@nestoca/ui";
import {
  Application,
  ApplicationState,
  APP_STATUSES_MAP,
  TRANSACTION_TYPE_LABEL,
} from "@shared/constants";
import { RateTypes } from "@shared/constants/application/product.const";
import { DatadogMask } from "@shared/datadog";
import { KeepI18ExprHappy, formatAddress } from "@shared/utils";
import Link from "next/link";
import { Trans, useTranslation } from "react-i18next";
import {
  BsFillArrowRightCircleFill,
  BsHouse,
  BsHouseFill,
} from "react-icons/bs";

import styles from "./application-card.module.scss";

type Props = {
  application: Application;
  href: URL | string;
  index: number;
};

export const ApplicationCard = ({ application, href, index }: Props) => {
  const { product, property, locked, created } = application;
  const { t } = useTranslation("applications");

  // *** NOTE: This is a workaround to avoid calling the API for each application
  // ***       to get the funded and closed status.
  // ***       This will avoid doing N+1 calls to the API.
  // ***       These hooks are derived from the single application API call.
  // ***       and cannot be call in this UI since they will create a performance issue.
  // ***       we already have the complete application object as a prop so we can use it.
  // const { data: isFunded } = useGetApplicationFundedStatus(application.id);
  // const { data: isClosed } = useGetApplicationClosedStatus(application.id);
  const isFunded = application.applicationState === "FUNDED";
  const isClosed = ["CLOSED", "EXPIRED"].includes(application.applicationState);

  const formattedAddress = useMemo(() => {
    return formatAddress(property?.address, "street");
  }, [property]);

  const formattedProduct = useMemo(() => {
    if (!product?.term && !product?.type) return;

    const type = t(RateTypes[product?.type] as KeepI18ExprHappy);
    const [term] = product.term.split("_");
    return {
      type,
      term,
    };
  }, [product, t]);

  const dateCreated = new Date(created).toISOString().split("T")[0];

  const text =
    APP_STATUSES_MAP[
      locked ? ApplicationState.Locked : application.applicationState
    ];

  return (
    <Link href={href} passHref legacyBehavior>
      <Flex
        className={styles.card}
        as="a"
        data-testid="application-card"
        data-dd-aaction-name="address card"
      >
        <Flex gap={3}>
          {isFunded ? (
            <BsHouseFill size={20} color="var(--color-green-600)" />
          ) : (
            <BsHouse size={20} />
          )}
          <Flex direction="column" gap={1}>
            {!formattedAddress ? (
              <Typography size={2} className={styles["card-address"]}>
                {t(
                  TRANSACTION_TYPE_LABEL[application.type] as KeepI18ExprHappy
                )}{" "}
                {index + 1}
              </Typography>
            ) : (
              <DatadogMask
                as={Typography}
                className={styles["card-address"]}
                data-dd-aaction-name="address"
              >
                {formattedAddress}
              </DatadogMask>
            )}
            <Typography size={0} className={styles["card-info"]}>
              <Trans
                t={t}
                i18nKey={
                  product?.type ? "application.term" : "application.noProduct"
                }
                values={{
                  term: formattedProduct?.term,
                  type: formattedProduct?.type,
                }}
              />
            </Typography>
            {!isFunded && (
              <Typography className={styles["card-info"]}>
                <Trans
                  t={t}
                  i18nKey={"application.dateCreated"}
                  values={{
                    dateCreated,
                  }}
                />
              </Typography>
            )}
          </Flex>
        </Flex>
        <Flex
          className={styles["card-chip"]}
          justify="between"
          direction="column"
        >
          <Chip
            label={t(text as KeepI18ExprHappy)}
            size="large"
            variant={
              (isFunded && "success") || (isClosed && "error") || "default"
            }
          />
          <BsFillArrowRightCircleFill className={styles["card-arrow"]} />
        </Flex>
      </Flex>
    </Link>
  );
};
