import {
  ObjectWithArrays,
  ObjectWithSerializedArrays,
} from "@shared/constants";

/**
 * This function is used to serialize the array properties of an object to a string
 * @param obj An object with array properties
 * @returns An object with array properties serialized to a string
 */
export const serializeObjectArrayValues = <T extends ObjectWithArrays>(
  obj: T
): ObjectWithSerializedArrays => {
  const updatedObject = {} as ObjectWithSerializedArrays;

  for (const key in obj) {
    const value = obj[key];

    if (Array.isArray(value)) {
      // Convert the array to a string
      updatedObject[key] = value.join(",");
    } else {
      updatedObject[key] = value;
    }
  }

  return updatedObject;
};
