import { useState } from "react";

import {
  Typography,
  Flex,
  ButtonIcon,
  useBreakpointValue,
  Button,
  Spinner,
} from "@nestoca/ui";
import { useDeleteCoApplicant } from "@shared/api/hooks/applications";
import { applicantSectionsRouterSchema } from "@shared/constants";
import { DatadogMask } from "@shared/datadog";
import { useApplicationDisabled } from "@shared/ui";
import { useTypedRouter } from "@shared/utils";
import { useTranslation } from "react-i18next";
import { BsFillPersonFill, BsPersonDashFill } from "react-icons/bs";

import styles from "./remove-co-applicant-modal.module.scss";
import { useNotification } from "./remove-co-applicant-provider";

type RemoveCoApplicantNameProps = {
  applicantId: number;
  firstName: string | null;
  lastName: string | null;
};

export const RemoveCoApplicantName = ({
  applicantId,
  firstName,
  lastName,
}: RemoveCoApplicantNameProps) => {
  const { t } = useTranslation("applications");
  const isMobile = useBreakpointValue({ default: true, md: false });

  const [selectedApplicantId, setSelectedApplicantId] = useState(0);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const {
    query: { applicationId },
  } = useTypedRouter(applicantSectionsRouterSchema);

  const { mutateAsync: deleteCoApplicant, isLoading } = useDeleteCoApplicant(
    applicationId,
    selectedApplicantId
  );

  const { showNotification } = useNotification();
  const { applicationDisabled } = useApplicationDisabled(applicationId);

  const onConfirmDelete = async () => {
    try {
      await deleteCoApplicant(undefined);
      showNotification("success", selectedApplicantId);
    } catch (error) {
      showNotification("error", selectedApplicantId);
    }
  };

  if (isLoading || confirmDelete) {
    return (
      <Flex
        key={applicantId}
        align="center"
        justify={isMobile ? "center" : "start"}
        gap={3}
        className={styles["remove-co-applicant-modal__applicant"]}
      >
        {!isMobile && (
          <ApplicantName firstName={firstName} lastName={lastName} />
        )}
        {isLoading ? (
          <Flex gap={4} align="center">
            <Typography size="00">{t("deleteApplicant.loading")}</Typography>
            <Spinner size="large" />
          </Flex>
        ) : (
          <Flex gap={4}>
            <Button
              variant="alternative"
              size="small"
              onClick={() => setConfirmDelete(false)}
            >
              {t("cancel")}
            </Button>

            <Button
              variant="critical"
              size="small"
              onClick={onConfirmDelete}
              disabled={applicationDisabled}
            >
              {t("deleteApplicant.confirmDelete")}
              <BsPersonDashFill />
            </Button>
          </Flex>
        )}
      </Flex>
    );
  }

  return (
    <Flex
      key={applicantId}
      align="center"
      justify={isMobile ? "center" : "start"}
      gap={isMobile ? 4 : 3}
      className={styles["remove-co-applicant-modal__applicant"]}
      onClick={() => {
        setSelectedApplicantId(applicantId);
        setConfirmDelete(true);
      }}
    >
      <ApplicantName firstName={firstName} lastName={lastName} />
      <ButtonIcon
        icon={<BsPersonDashFill />}
        aria-label="remove"
        size="small"
        variant="alternative"
        className={styles["remove-button"]}
      />
    </Flex>
  );
};

const ApplicantName = ({
  firstName,
  lastName,
}: {
  firstName: string | null;
  lastName: string | null;
}) => (
  <>
    <Flex
      className={styles["remove-co-applicant-modal__applicant-icon"]}
      align="center"
      justify="center"
    >
      <BsFillPersonFill size="18" color="var(--color-blue-500)" />
    </Flex>
    <DatadogMask
      as={Typography}
      variant="secondary"
      data-dd-action-name="remove co-applicant"
      className={styles["remove-co-applicant-modal__applicant-text"]}
    >
      {`${firstName} ${lastName}`}
    </DatadogMask>
  </>
);
