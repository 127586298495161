import { api } from "@shared/api/client";
import { api as apiNoHeaders } from "@shared/api/externalClient";

import type {
  AddDocumentFile,
  AddDocumentFilePayload,
  DocumentCompositeId,
  GetDocumentsCountsResponse,
  GetDocumentsPayload,
  GetDocumentsResponse,
  GetSignedUrlRequestPayload,
  GetSignedUrlResponse,
  UploadDocumentPayload,
} from "./types";

export const getDocuments = async ({
  applicationId,
  applicantId,
  step = 0,
}: GetDocumentsPayload) => {
  const { data } = await api.get<GetDocumentsResponse[]>(
    `/applications/${applicationId}/applicants/${applicantId}/documents?step=${step}`
  );

  return data;
};

/**
 * Gets a signed google storage URL that will be used to upload the document.
 * @param applicationId The application's ID
 * @param applicantId: The applicant's ID
 * @param documentType: The document type
 * @param year: The year of the document
 * @param entityId: The entity ID of the document
 * @returns The signed URL to upload the document
 */
const getSignedUrl = async (
  {
    applicationId,
    applicantId,
    documentType,
    year = 0,
    entityId = 0,
  }: GetSignedUrlRequestPayload,
  { type, name }: File
) => {
  const { data } = await api.put<GetSignedUrlResponse>(
    `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/files/upload`,
    { contentType: type, originalFileName: name }
  );

  return data;
};

/**
 * Uploads the file to google storage.
 * @param signedUrl {string} The signed URL to upload the file
 * @param file {File} The file to upload
 */
const uploadFile = (signedUrl: string, file: File) =>
  apiNoHeaders.put(signedUrl, file, {
    headers: {
      "Content-Type": "",
    },
  });

const addDocumentFile = async (
  {
    applicationId,
    applicantId,
    documentType,
    year = 0,
    entityId = 0,
  }: AddDocumentFilePayload,
  file: AddDocumentFile
) => {
  const { data } = await api.post(
    `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/files`,
    file
  );
  return data;
};

/**
 * Uploads a new document.
 * This is done in 3 steps:
 * - Get a signed URL to upload the document
 * - Upload the document to google storage
 * - Confirm the document upload and store the file data in the applicant's record
 *
 * @param applicationId {number} The id of the application
 * @param applicantId {number} The id of the applicant
 * @param type {string} The type of the document
 * @param file {File} The document to upload
 * @returns The updated logo details
 */
export const uploadDocument = async ({
  applicationId,
  applicantId,
  documentType,
  year = 0,
  entityId = 0,
  file,
  retrieveId,
}: UploadDocumentPayload & { retrieveId: string }) => {
  let newFile: GetSignedUrlResponse;

  try {
    newFile = await getSignedUrl(
      { applicationId, applicantId, documentType, year, entityId },
      file
    );
  } catch (error) {
    console.error("Error getting signed URL", error);
    throw error;
  }

  try {
    await uploadFile(newFile.url, file);
  } catch (error) {
    console.error("Error uploading file", error);
    throw error;
  }

  let documentFile;
  try {
    documentFile = await addDocumentFile(
      {
        applicantId,
        applicationId,
        documentType,
        year,
        entityId,
      },
      newFile
    );
  } catch (error) {
    console.error("Error confirming file upload", error);
    throw error;
  }
  return {
    ...documentFile,
    retrieveId,
  };
};

export const getDocumentsCounts = async (applicationId: number) => {
  const { data } = await api.get<GetDocumentsCountsResponse>(
    `/applications/${applicationId}/documents/counts?step=0`
  );

  return data;
};

export const getDocumentFileUrl = async ({
  fileId,
  documentCompositeId: {
    applicationId,
    applicantId,
    documentType,
    year = 0,
    entityId = 0,
  },
  params,
}: {
  fileId: string;
  documentCompositeId: DocumentCompositeId;
  params?: Partial<{
    forceDownload: boolean;
    convert: boolean;
  }>;
}) => {
  const { data } = await api.get<GetSignedUrlResponse>(
    `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/files/${fileId}/download`,
    { params }
  );

  return data;
};

export const deleteDocumentFile = async ({
  fileId,
  documentCompositeId: {
    applicationId,
    applicantId,
    documentType,
    year = 0,
    entityId = 0,
  },
}: {
  fileId: string;
  documentCompositeId: DocumentCompositeId;
}) => {
  // this endpoint returns `{}`, so we won't need to send the response back to the frontend
  await api.delete<never>(
    `/applications/${applicationId}/applicants/${applicantId}/documents/${documentType}/${year}/${entityId}/files/${fileId}`
  );
};
