import { Flex, Button } from "@nestoca/ui";
import {
  useGetCoApplicants,
  useGetMainApplicant,
} from "@shared/api/hooks/applications";
import { getApplicantName } from "@shared/utils";
import clsx from "clsx";
import Link from "next/link";
import { useTranslation } from "react-i18next";

import styles from "./applicant-selector.module.scss";

interface ApplicantSelectorBtnLinkProps {
  applicationId: number;
  selectedApplicantId: number;
}

export const ApplicantSelectorBtnLink = ({
  applicationId,
  selectedApplicantId,
}: ApplicantSelectorBtnLinkProps) => {
  const { data: mainApplicant } = useGetMainApplicant(applicationId);
  const { data: coApplicants } = useGetCoApplicants(applicationId);
  const { t } = useTranslation("applications");
  const mainApplicantName = getApplicantName(mainApplicant, t);
  return (
    <Flex gap={2}>
      {mainApplicant && (
        <Link
          key={`applicant-${applicationId}-${mainApplicant.applicantId}}`}
          passHref
          href={`/applications/${applicationId}/applicant/${mainApplicant.applicantId}`}
          legacyBehavior
        >
          <Button
            as="a"
            variant="secondary"
            className={clsx(styles["applicant-button"], {
              [styles["applicant-button--selected"]]:
                selectedApplicantId &&
                mainApplicant.applicantId === selectedApplicantId,
            })}
            data-dd-action-name="main applicant"
          >
            {mainApplicantName}
          </Button>
        </Link>
      )}
      <>
        {coApplicants?.map((applicant) => {
          const applicantName = getApplicantName(applicant, t);

          return (
            <Link
              key={`applicant-${applicationId}-${applicant.applicantId}}`}
              passHref
              href={`/applications/${applicationId}/applicant/${applicant.applicantId}`}
              legacyBehavior
            >
              <Button
                as="a"
                key={applicant.applicantId}
                variant="secondary"
                className={clsx(styles["applicant-button"], {
                  [styles["applicant-button--selected"]]:
                    selectedApplicantId &&
                    applicant.applicantId === selectedApplicantId,
                })}
                data-dd-action-name="co-applicant"
              >
                {applicantName}
              </Button>
            </Link>
          );
        })}
      </>
    </Flex>
  );
};
