import { useCallback, useMemo, useState } from "react";

import { Box, Flex, Typography, OverflowMenu } from "@nestoca/ui";
import { useGetAccount, useMutateAccount } from "@shared/api/hooks/account";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { toast } from "react-toastify";

import styles from "./burger-menu.module.scss";

type OptionType = {
  id: "en" | "fr";
  label: string;
  selected: boolean;
};

export const LanguageSelector = ({
  changePreferredLanguage = true,
}: {
  changePreferredLanguage?: boolean;
}) => {
  const { t } = useTranslation("common");
  const { locale, asPath, replace } = useRouter();
  const [language, setLanguage] = useState(locale);
  const selectedLanguage =
    language === "en" ? t("form:language.english") : t("form:language.french");

  const { data: account } = useGetAccount();
  const accountMutation = useMutateAccount();

  const options = useMemo<OptionType[]>(
    () => [
      {
        id: "en",
        label: t("form:language.english"),
        selected: language === "en",
      },
      {
        id: "fr",
        label: t("form:language.french"),
        selected: language === "fr",
      },
    ],
    [language, t]
  );

  const LanguageButton = (
    _value: OptionType | undefined,
    isLangMenuOpen: boolean | undefined
  ) => (
    <Flex className={styles["language-button"]} gap={4}>
      <Box className={styles["language-button-name"]}>
        {t(selectedLanguage)}
      </Box>
      {isLangMenuOpen ? (
        <BsChevronUp size="1rem" color={"var(--color-brand)"} />
      ) : (
        <BsChevronDown size="1rem" color={"var(--color-brand)"} />
      )}
    </Flex>
  );

  const Option = (option: OptionType) => (
    <Box
      className={clsx(styles["language-menu-item"], {
        [styles["language-menu-item--selected"]]: option.selected,
      })}
      data-dd-action-name="language selector option"
    >
      {option.label}
    </Box>
  );

  const onOptionClick = useCallback(
    ({ id: selectedLanguage }: OptionType) => {
      setLanguage(selectedLanguage);

      !changePreferredLanguage &&
        replace(asPath, asPath, {
          locale: selectedLanguage,
        });

      if (!account) {
        return toast(t("failedToSave", { ns: "error" }), { type: "error" });
      }

      changePreferredLanguage &&
        accountMutation.mutate(
          { ...account, preferredLanguage: selectedLanguage },
          {
            onSuccess: () => {
              replace(asPath, asPath, {
                locale: selectedLanguage,
              });
            },
            onError: () => {
              toast(t("dataUpdatedFailure", { ns: "applications" }), {
                type: "error",
              });
            },
          }
        );
    },
    [account, asPath, replace, t, accountMutation, changePreferredLanguage]
  );

  return (
    <Flex align="center" justify="between" className={styles["language"]}>
      <Typography size={0} weight={5}>
        {t("language.label")}
      </Typography>
      <OverflowMenu
        className={styles["language-menu"]}
        options={options}
        getOptionKey={(option) => option.id}
        button={LanguageButton}
        renderOption={Option}
        menuPlacement="left"
        onOptionClick={onOptionClick}
      />
    </Flex>
  );
};
