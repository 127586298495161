import { useState, useEffect } from "react";

import { useTenant } from "@nestoca/multi-tenant";
import { ButtonIcon, Flex, useBreakpointValue } from "@nestoca/ui";
import { useGetAccount } from "@shared/api/hooks/account";
import { GiHamburgerMenu } from "react-icons/gi";

import { BurgerMenu } from "./burger-menu/burger-menu";
import { NavbarLogo } from "./navbar-logo";
import styles from "./navbar.module.scss";

export const AuthenticatedMobileHeader = () => {
  const tenant = useTenant();
  const { isLoading, isError, error } = useGetAccount();
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = useBreakpointValue({ default: true, md: false });

  // disable scroll when menu is open
  useEffect(() => {
    const body = document.body;

    if (menuOpen) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "visible";
    }

    return () => {
      body.style.overflow = "visible";
    };
  }, [menuOpen]);

  if (isLoading || !tenant || !isMobile) {
    return null;
  }

  // When query is failing, we want to throw the error to the error boundary
  // it happen only when the hook is in the layout function on th `_app` `getLayout`
  if (isError) {
    throw error;
  }

  return (
    <>
      <Flex
        className={styles.header}
        direction="row"
        align="center"
        justify="between"
      >
        <NavbarLogo />
        <ButtonIcon
          variant="ghost"
          aria-label="open burger menu"
          icon={<GiHamburgerMenu />}
          size="medium"
          onClick={() => setMenuOpen(true)}
          data-testid="open-burger-menu"
          data-dd-action-name="open burger menu"
          className={styles["burger-menu-button"]}
          isRound={true}
        />
      </Flex>
      {menuOpen && <BurgerMenu setMenuOpen={setMenuOpen} />}
    </>
  );
};
