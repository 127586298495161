import type { CreditReport } from "./credit-report.types";
import type {
  CoOwnershipFees,
  OtherProperty,
  AssetResponse,
  Lender,
  FinancialInstitutionEnum,
  YesNo,
  AmountFrequency,
  Liabilities,
  CreditScoreQualityEnum,
  OtherIncomeEnum,
} from "@shared/constants";

export interface Applicant extends InstitutionDetails {
  applicantId: number;
  addresses: RegisteredAddress[];
  allAssets: AssetResponse[];
  assets?: {
    giftFromImmediateRelative: {
      familyRelationship: string;
      amount: number;
      willUseForDownPayment: boolean;
      amountUsedForDownPayment: number;
    };
    houseHoldGoods: {
      description: string;
      value: number;
    };
    otherAssets: any[];
    otherInvestments: {
      amount: number;
      willUseForDownPayment: boolean;
      amountUsedForDownPayment: number;
    };
    properties: any[];
    registeredRetirementSavingsPlan: {
      amount: number;
      willUseForDownPayment: boolean;
      amountUsedForDownPayment: number;
    };
    savingsAccount: {
      amount: number;
      willUseForDownPayment: boolean;
      amountUsedForDownPayment: number;
    };
    taxFreeSavingsAccount: {
      amount: number;
      willUseForDownPayment: boolean;
      amountUsedForDownPayment: number;
    };
    vehicles: any[];
  };
  dateOfBirth: string;
  email: string;
  firstName: string | null;
  firstTimeHomeBuyer?: boolean;
  guarantor: boolean | null;
  income: {
    employments: IncomeEmployment[];
    others: IncomeOther[];
  };
  lastName: string | null;
  liabilities: Liabilities | null;
  permissions: string;
  creditReport: CreditReport | null;
  maritalStatus: string;
  phone: string;
  homePhone?: string | null;
  newToCanada?: boolean | null;
  residencyStatus?: string | null;
  numberOfDependents?: number | null;
  properties: OtherProperty[];
  relationToMainApplicant: string;
  salutation: string;
  socialInsuranceNumber?: string;
  otherIncomesSpecified: boolean;
  propertiesSpecified: boolean;
  creditScoreQuality: CreditScoreQualityEnum | "";
  /** deprecated */
  covid19Impacted: boolean | null;
  /** deprecated */
  covid19ImpactDescription: string | null;
  hasConsumerProposalOrBankruptcyLast5yrs: YesNo | "";
  financialInstitutionBankID?: string | null;
  financialInstitutionAccountNumber?: number | null;
  financialInstitutionTransitNumber?: number | null;
  isNestoEmployee?: boolean | null;
  gender?: string[] | null;
  creditPullConsentDate?: string | null;
}

export interface RegisteredAddress {
  id?: number;
  isCurrentAddress?: boolean;
  occupiedYears: number;
  occupiedMonths: number;
  situation: RegisteredAddressType;
  rent?: {
    amount: number;
    frequency: "WEEKLY" | "MONTHLY" | "ANNUALLY";
  };
  address: Address;
}

export type RegisteredAddressType = "RENTING" | "OWNER" | "LIVING_WITH_PARENTS";

export const RegisteredAddressTypeEnum = {
  RENTING: "RENTING",
  OWNER: "OWNER",
  LIVING_WITH_PARENTS: "LIVING_WITH_PARENTS",
} as const;

export type Address = {
  addressLine?: string;
  city: string;
  country?: string;
  countryCode: string;
  postalCode: string;
  state?: string;
  stateCode: string;
  street: string;
  streetNumber: string;
  unit?: string;
};

/**
 * @ref https://github.com/nestoca/applications/blob/c0ca5b3ace89033027430831e9e4f584614dd6e4/api/applications_applicant_incomes.go#L61
 */
export type IncomeEmployment = {
  readonly id?: number;
  employer: {
    name: string;
    address: Address;
    phone: string;
  };
  jobTitle: string;
  industry: EmploymentIndustry;
  isCurrent: boolean;
  hasGuaranteedHours?: boolean;
  employmentType: EmploymentType;
  incomeType: EmploymentIncomeType;
  pensionType: PensionType | null;
  tenure: EmploymentTenure;
  employedYears: number | null;
  employedMonths: number | null;
  salary: IncomeEmploymentSalary;
  salaryPreviousYear: IncomeEmploymentSalary;
  selfEmployed: {
    companyType: string | null;
    operatingAs: SelfEmployedOperatingAs | null;
    grossRevenue: AmountFrequency | null;
    grossRevenuePreviousYear: AmountFrequency | null;
  };
  yearsInIndustry: number;
  monthsInIndustry: number;
  incomeOverride: AmountFrequency;
  incomeOverrideIncluded: boolean;
  // submission notes
  comments?: string;
};

export type IncomeOther = {
  readonly id?: number;
  // submission notes
  comments?: string;
  description: string;
  type: OtherIncomeType;
  income: AmountFrequency;
  ratiosIncluded?: boolean;
};

export type OwnedProperties = {
  readonly id?: number;
  hasMortgage: string | boolean;
  currentPurpose: Purpose;
  afterTransactionPurpose: PurposeAfterTransaction;
  currentSaleStatus?: PropertySaleStatus;
  address: Address;
  type: OtherPropertyType | "";
  estimatedPropertyValue: number;
  condoFees: Partial<CoOwnershipFees>;
  taxes: AmountYear;
  heatingCost: AmountFrequency;
  rentalIncome: AmountFrequency;
  purchasePrice: number;
  mortgage: MortgageProperty;
  sellingDate: string;
  applicantId?: number;
  coOwnerApplicantIds?: number[];
  taxesIncluded?: boolean;
  rentalIncomeIncluded?: boolean;
  condoFeesIncluded?: boolean;
  heatingCostIncluded?: boolean;
  rentalIncomeRatio?: number;
  bridgeLoanAmount: number;
  bridgeLoanRate: number;
  portIndicator?: boolean;
};

export type InterestRateType =
  | "FIXED"
  | "VARIABLE"
  | "ADJUSTABLE"
  | "CAPPED_VARIABLE";

export type TermType = "OPEN" | "CLOSED" | "CONVERTABLE";

export type MortgageType = "STANDARD" | "HELOC";

export type MortgageProperty = {
  balance: number;
  existingLoanNumber: string;
  interestRate: number;
  interestRateType: InterestRateType;
  lender: Lender;
  maturityDate: string;
  mortgageType: MortgageType;
  payment: MortgageAmountFrequency;
  termType: TermType;
};

export type MortgageAmountFrequency = {
  amount: number;
  frequency: MortgagePaymentFrequency;
};

export type RentalIncomeFrequency =
  | "WEEKLY"
  | "BIWEEKLY"
  | "MONTHLY"
  | "SEMIANNUALLY"
  | "SEMIMONTHLY"
  | "ANNUALLY";

export type MortgagePaymentFrequency =
  | "WEEKLY"
  | "ACCELERATED_WEEKLY"
  | "BIWEEKLY"
  | "ACCELERATED_BIWEEKLY"
  | "SEMIMONTHLY"
  | "MONTHLY";

export type AmountYear = { amount: number; year: number };

export type OtherPropertyType =
  | "HOUSE"
  | "CONDO"
  | "SECONDARY_HOME_OR_COTTAGE"
  | "DUPLEX"
  | "TRIPLEX"
  | "FOURPLEX";

export type PropertySaleStatus =
  | "NOT_READY_YET"
  | "LISTED_FOR_SALE"
  | "CONDITIONALLY_SOLD"
  | "SOLD_FIRM";

export type PurposeAfterTransaction =
  | "SOLD"
  | "OWNER_OCCUPIED"
  | "OWNER_OCCUPIED_AND_RENTAL"
  | "RENTAL";

export type Purpose = "OWNER_OCCUPIED" | "OWNER_OCCUPIED_AND_RENTAL" | "RENTAL";

/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L231
 */
export type OtherIncomeType = keyof typeof OtherIncomeEnum;

/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L177
 */
export type EmploymentIndustry =
  | "BANK_FINANCE"
  | "MANUFACTURING"
  | "GOVERNMENT"
  | "HEALTH"
  | "RETAIL"
  | "HIGH_TECH"
  | "EDUCATION"
  | "LEISURE_ENTERTAINMENT"
  | "SERVICES"
  | "TRANSPORTATION"
  | "NATURAL_RESOURCES"
  | "CONSTRUCTION"
  | "FARMING";

/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L164
 */
export type EmploymentType = "FULL_TIME" | "PART_TIME" | "SEASONAL";

/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L210
 */
export type EmploymentIncomeType =
  | "NONE"
  | "SALARIED"
  | "HOURLY"
  | "HOURLY_COMMISSIONS"
  | "COMMISSIONS"
  | "SELF_EMPLOYED"
  | "PENSION";

/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L276
 */
export type PensionType =
  | "OLD_AGE_SECURITY"
  | "CANADIAN_PENSION_PLAN"
  | "EMPLOYER"
  | "OTHER";

/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L250
 */
export type EmploymentTenure = "PERMANENT" | "ON_PROBATION" | "ON_CONTRACT";

/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L263
 */
export type SelfEmployedOperatingAs =
  | "CORPORATION"
  | "PARTNERSHIP"
  | "SOLE_PROPRIETOR";

export type IncomeEmploymentSalary = {
  base?: AmountFrequency;
  baseRatiosIncluded: boolean;
  bonus?: AmountFrequency;
  bonusRatiosIncluded: boolean;
  commission?: AmountFrequency;
  commissionRatiosIncluded: boolean;
  overtime?: AmountFrequency;
  overtimeRatiosIncluded: boolean;
};

export type InstitutionDetails = {
  primaryBankingInstitution: FinancialInstitutionEnum;
  primaryBankingInstitutionOther?: string | null;
};
