import {
  deleteAccount,
  getAccount,
  updateAccount,
} from "@shared/api/applications";
import { keyFactory } from "@shared/api/hooks/utils";
import { useDatadogUser } from "@shared/datadog";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type { Account, DeleteAccountFormData } from "@shared/constants";

const accountKeys = keyFactory("account");

export const useGetAccount = <TResult extends Account = Account>(
  select?: (data: Account) => TResult,
  enabled?: boolean
) => {
  const account = useQuery(accountKeys.detail(), getAccount, {
    select,
    enabled,
  });

  useDatadogUser(
    account.isSuccess
      ? {
          id: `${account.data.id}`,
          name: `${account.data.firstName}`,
          rid: account.data.rid,
        }
      : undefined
  );

  return account;
};

export const useMutateAccount = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newAccountInfo: Account) =>
      updateAccount(newAccountInfo),
    onSettled() {
      queryClient.invalidateQueries({
        queryKey: accountKeys.detail(),
      });
    },
    retry: 3,
  });
};

export const useDeleteAccount = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (feedback: DeleteAccountFormData) =>
      deleteAccount(feedback),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: accountKeys.detail(),
      });
    },
    retry: 2,
  });
};
