import { Grid } from "@nestoca/ui";
import {
  ApplicationWithIndex,
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from "@shared/ui";

import { ApplicationCard } from "../application-card";
import { ApplicationCardSkeleton } from "../application-card/application-card-skeleton";

import styles from "./application-selector.module.scss";

interface ApplicationSelectorGridProps {
  getApplicationUrl: (application: ApplicationWithIndex) => URL | string;
  applications: ApplicationWithIndex[];
}

export const ApplicationSelectorGrid = ({
  getApplicationUrl,
  applications,
}: ApplicationSelectorGridProps) => (
  <ScrollAreaRoot>
    <ScrollAreaViewport>
      <Grid className={styles["application-selector__list"]} gap={5}>
        {applications?.length >= 0 && (
          <ApplicationsList
            applications={applications}
            getApplicationUrl={getApplicationUrl}
          />
        )}
      </Grid>
    </ScrollAreaViewport>
    <ScrollAreaScrollbar orientation="horizontal">
      <ScrollAreaThumb />
    </ScrollAreaScrollbar>
  </ScrollAreaRoot>
);

export const ApplicationSelectorGridSkeleton = () => (
  <Grid className={styles["application-selector__list"]} gap={5}>
    <ApplicationCardSkeleton />
    <ApplicationCardSkeleton />
  </Grid>
);

interface ApplicationsListProps extends ApplicationSelectorGridProps {
  applications: ApplicationWithIndex[];
}

const ApplicationsList = ({
  applications,
  getApplicationUrl,
}: ApplicationsListProps) => {
  const items = [];
  // Reverse the array so that the most recent application is at the top
  // application order is sort in the API response by created ASC
  // so we need to reverse it to have the most recent application at the top
  // and make sure the mortgage N is always the same index and reversed
  for (let i = applications.length - 1; i >= 0; i--) {
    const application = applications[i];

    items.push(
      <ApplicationCard
        key={application.id}
        index={application.index}
        application={application}
        href={getApplicationUrl(application)}
      />
    );
  }

  return <>{items}</>;
};
