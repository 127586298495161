import * as z from "zod";

// *** NOTE: importing from @shared/utils is not working ***
// *** so we are importing with relative path the functions here ***
// It create a circular dependency
import { getAsNumber } from "../../utils/get-as-number";
import { isEmpty } from "../../utils/is-empty";

import {
  MAX_VALUE,
  financialInstitutions,
  financialTypes,
} from "./asset.const";
import {
  AssetTypeEnum,
  FamilyRelationshipEnum,
  FinancialInstitutionEnum,
} from "./asset.enum";
import { isFinancialAsset } from "./asset.guards";

import type { DistributiveOmit } from "@shared/utils";

// https://github.com/nestoca/applications-tmp/blob/daa7069912ca2e93ac779ddc3b9990b71bbf45bd/api/applications_applicant_assets.go
export const withId = z.object({ id: z.coerce.number() });

export const base = z.object({
  id: z
    .preprocess(
      (v) => (isEmpty(v) ? null : getAsNumber(v)),
      z.number({
        required_error: "form:error.required",
        invalid_type_error: "form:error.required",
      })
    )
    .optional(),
  amountUsedForDownPayment: z.preprocess(
    (v) => (isEmpty(v) ? null : getAsNumber(v)),
    z
      .number({
        required_error: "form:error.required",
        invalid_type_error: "form:error.required",
        // will coerce nullish values to 0
        // so we can use it as a default value and make it optional
        coerce: true,
      })
      .max(MAX_VALUE, "form:error.maxDollarAmount")
  ),

  // we dont have this value in CMA, so we dont need to validate it
  // it will be transformed to from amountUsedForDownPayment in transformer of onSubmit // is it to or from?
  // value:  z.number(),
  value: z.any(),
});

// Gift
export const giftAsset = z
  .object({
    type: z.literal(AssetTypeEnum.GIFT),
    familyRelationship: z.nativeEnum(FamilyRelationshipEnum, {
      required_error: "form:error.required",
      invalid_type_error: "form:error.required",
    }),
    donorFirstName: z
      .string({
        required_error: "form:error.required",
        invalid_type_error: "form:error.required",
      })
      .trim()
      .min(1, { message: "form:error.required" }),
    donorLastName: z
      .string({
        required_error: "form:error.required",
        invalid_type_error: "form:error.required",
      })
      .trim()
      .min(1, { message: "form:error.required" }),
  })
  .merge(base);

// Property
export const propertyAsset = z
  .object({
    type: z.literal(AssetTypeEnum.PROPERTY),
    existingPropertyId: z.preprocess(
      (v) => (isEmpty(v) ? null : getAsNumber(v)),
      z.number({
        invalid_type_error: "form:error.required",
        required_error: "form:error.required",
      })
    ),
  })
  .merge(base);

// Vehicle
export const vehicleAsset = z
  .object({
    type: z.literal(AssetTypeEnum.VEHICLE),
    make: z
      .string({
        required_error: "form:error.required",
        invalid_type_error: "form:error.required",
      })
      .trim()
      .min(1, {
        message: "form:error.required",
      })
      .max(64, { message: "form:error.tooLong" }),
    model: z
      .string({
        required_error: "form:error.required",
        invalid_type_error: "form:error.required",
      })
      .trim()
      .min(1, {
        message: "form:error.required",
      })
      .max(64, { message: "form:error.tooLong" }),
    year: z.coerce
      .number({
        required_error: "form:error.required",
        invalid_type_error: "form:error.required",
      })
      .min(1800, { message: "form:error.invalidYear" })
      .max(new Date().getFullYear() + 1, { message: "form:error.invalidYear" }),
    description: z.string().trim().optional(),
    // this type is an exception because we can't use it as down payment
    // so `value` is shown but not `amountUsedForDownPayment`
    value: z.preprocess(
      (v) => (isEmpty(v) ? null : getAsNumber(v)),
      z
        .number({
          required_error: "form:error.required",
          invalid_type_error: "form:error.required",
          coerce: false,
        })
        .min(0)
        .max(MAX_VALUE, "form:error.maxDollarAmount")
    ),
  })
  .merge(base.omit({ value: true }));

// Financial
// https://github.com/nestoca/applications-tmp/blob/daa7069912ca2e93ac779ddc3b9990b71bbf45bd/api/applications_applicant_assets.go#L202-L234
// Can't use discriminate union because it doesn't support nested discriminated unions
// so this one will be validated with `refine`
export const financialOtherAsset = z
  .object({
    type: z.enum(financialTypes),
    institution: z.literal(FinancialInstitutionEnum.OTHER, {
      required_error: "form:error.required",
      invalid_type_error: "form:error.required",
    }),
    institutionOther: z
      .string({
        required_error: "form:error.required",
        invalid_type_error: "form:error.required",
      })
      .trim()
      .min(1, { message: "form:error.required" })
      .max(50, { message: "form:error.tooLong" }),
  })
  .merge(base);

export const financialOptionalOtherInstitution = financialOtherAsset
  .omit({ institution: true })
  .merge(
    z.object({
      institution: z
        .string({
          required_error: "form:error.required",
          invalid_type_error: "form:error.required",
        })
        .trim()
        .min(1),
    })
  )
  .partial({
    institutionOther: true,
  });

export const financialInstitutionAsset = z
  .object({
    type: z.enum(financialTypes, {
      required_error: "form:error.required",
      invalid_type_error: "form:error.required",
    }),
    institution: z.enum(financialInstitutions, {
      required_error: "form:error.required",
      invalid_type_error: "form:error.required",
    }),
  })
  .merge(base);

export const financialAsset = z.discriminatedUnion("institution", [
  financialOtherAsset,
  financialInstitutionAsset,
]);

// Other
export const otherAsset = z
  .object({
    type: z.literal(AssetTypeEnum.OTHER),
    name: z
      .string({
        required_error: "form:error.required",
        invalid_type_error: "form:error.required",
      })
      .trim()
      .min(1, { message: "form:error.required" }),
    description: z.string().trim().optional(),
  })
  .merge(base);

const validationFinancialRefine = <T extends BaseAsset>(data: T) => {
  if (!isFinancialAsset(data)) {
    return true;
  }

  // custom validataion for financial assets
  const res = financialAsset.safeParse(data);
  return res.success;
};

const assetTransformer = <T extends BaseAsset>(data: T) => {
  //
  if (data.type === AssetTypeEnum.VEHICLE) {
    return {
      ...data,
      amountUsedForDownPayment: null,
    };
  }

  if (data.type === AssetTypeEnum.PROPERTY) {
    // Property we dont want to override the value of of it from the backend.
    // BE will sync the value from the property or own properties sold after transaction
    return data;
  }

  // **IMPORTANT**
  // this will always set `value` at the same value as `amountUsedForDownPayment`
  // this is an expected behaviour since we are showing only `amountUsedForDownPayment` in CMA
  // We have the stamp of approval from the business (Adham Hassan) to do this
  // we are aware that this might impact the total value in MO but we dont care about it
  return { ...data, value: data.amountUsedForDownPayment };
};

export const baseAssetSchema = z.discriminatedUnion("type", [
  giftAsset,
  propertyAsset,
  vehicleAsset,
  financialOptionalOtherInstitution,
  otherAsset,
]);

const baseSchemaExists = z.discriminatedUnion("type", [
  giftAsset.merge(withId),
  propertyAsset.merge(withId),
  vehicleAsset.merge(withId),
  financialOptionalOtherInstitution.merge(withId),
  otherAsset.merge(withId),
]);

export const schemaAsset = baseSchemaExists
  .transform(assetTransformer)
  .refine(validationFinancialRefine, {
    message: "institutionOther is required when institution is OTHER",
    path: ["institutionOther"],
  });

const baseSchemaAssetCreate = z.discriminatedUnion("type", [
  giftAsset,
  propertyAsset,
  vehicleAsset,
  financialOptionalOtherInstitution,
  otherAsset,
]);

export const schemaAssetCreate = baseSchemaAssetCreate
  .transform(assetTransformer)
  .refine(validationFinancialRefine, {
    message: "institutionOther is required when institution is OTHER",
    path: ["institutionOther"],
  });

// Types
// Cannot extract these types to another file because it will create a circular dependency
// since we are using the utils functions `z.infer` to create the types

export type BaseAsset = z.infer<typeof baseAssetSchema>;
export type ExistingAsset = z.infer<typeof schemaAsset>;

export type Asset = z.infer<typeof schemaAsset>;

export type CreateAsset = z.infer<typeof schemaAssetCreate>;

// **IMPORTANT**
// this is used for the query selector to know which applicant is assigned to the existing asset
export type AssetWithApplicant = Asset & {
  applicant: {
    applicantId: number;
    firstName: string;
    lastName: string;
  };
};

// Type guards utilities
export type GiftAsset = z.infer<typeof giftAsset> & z.infer<typeof withId>;
export type PropertyAsset = z.infer<typeof propertyAsset> &
  z.infer<typeof withId>;
export type VehicleAsset = z.infer<typeof vehicleAsset> &
  z.infer<typeof withId>;
export type FinancialOtherAsset = z.infer<typeof financialOtherAsset> &
  z.infer<typeof withId>;
export type FinancialInstitutionAsset = z.infer<
  typeof financialInstitutionAsset
> &
  z.infer<typeof withId>;
export type FinancialAsset = z.infer<typeof financialAsset> &
  z.infer<typeof withId>;
export type OtherAsset = z.infer<typeof otherAsset> & z.infer<typeof withId>;

// AssetResponse is the response from<>to the BE
export type AssetResponse = DistributiveOmit<Asset, "applicantId">;
