import { DocumentEntityType, DocumentStep } from "@shared/constants";

import type {
  AugmentedDocumentFile,
  GetDocumentsResponse,
} from "@shared/api/applications/applicants";
import type {
  Application,
  Asset,
  IncomeEmployment,
  IncomeOther,
  OtherProperty,
  RegisteredAddress,
} from "@shared/constants";

export type UpdateInCallbackItemFn<T> = (item: T) => T;

export function updateIn<T = unknown>(
  collections: T[],
  predicate: (value: T, index: number, obj: T[]) => boolean,
  itemCallback: UpdateInCallbackItemFn<T>
): T[] {
  const index = collections.findIndex(predicate);
  // item not found
  // return the same collection
  if (index === -1) {
    return collections;
  }

  const foundItem = collections[index];

  const newItem = itemCallback(foundItem);

  return [
    ...collections.slice(0, index),
    newItem,
    ...collections.slice(index + 1),
  ];
}

export const predicateDocumentByCompositeKey =
  ({
    applicationId,
    applicantId,
    documentType,
    year,
    entityId = 0,
  }: Pick<
    GetDocumentsResponse,
    "applicationId" | "applicantId" | "documentType" | "year" | "entityId"
  >) =>
  (document: GetDocumentsResponse) =>
    document.applicationId === applicationId &&
    document.applicantId === applicantId &&
    document.documentType === documentType &&
    document.entityId === entityId &&
    document.year === year;

export const predicateFileByRetrieveId =
  (retrieveId: string) =>
  (file: AugmentedDocumentFile & { retrieveId?: string }) =>
    file.retrieveId === retrieveId;

type UpdateFileInDocumentsParams = {
  documentCollections: GetDocumentsResponse[];
  documentPredicateFn: (
    value: GetDocumentsResponse,
    index: number,
    obj: GetDocumentsResponse[]
  ) => boolean;
  filePredicateFn: (
    value: AugmentedDocumentFile,
    index: number,
    obj: AugmentedDocumentFile[]
  ) => boolean;
  fileCallback: (file: AugmentedDocumentFile) => AugmentedDocumentFile;
};

export const updateFileInDocuments = ({
  documentCollections,
  documentPredicateFn,
  filePredicateFn,
  fileCallback,
}: UpdateFileInDocumentsParams) => {
  // find the document with compositeKey
  const currentDocument = documentCollections.find(documentPredicateFn);

  if (!currentDocument?.files) {
    return documentCollections;
  }

  // find the file with retrieveId
  const files = updateIn(currentDocument.files, filePredicateFn, fileCallback);

  // return the updated documents collection
  return updateIn(documentCollections, documentPredicateFn, () => ({
    ...currentDocument,
    files,
  }));
};

//// --------------------

/**
 * Takes a list of documents and groups them by step (urgent and additional)
 *
 * @param documents the list of documents
 */
export const getDocumentsByStep = (documents: GetDocumentsResponse[]) => {
  const result: Record<string, GetDocumentsResponse[]> = {
    urgentDocuments: [],
    additionalDocuments: [],
  };

  documents.forEach((document) => {
    if (document.step === DocumentStep.Urgent) {
      result.urgentDocuments.push(document);
    } else {
      result.additionalDocuments.push(document);
    }
  });

  return result;
};

// Path Array mapping
const categoryPathMapping = {
  FINANCIALS: {
    undefined: {
      path: ["allAssets"],
      componentType: DocumentEntityType.asset,
    },
    FINANCIALS_ASSETS: {
      path: ["allAssets"],
      componentType: DocumentEntityType.asset,
    },
    FINANCIALS_LIABILITIES: {
      path: ["allAssets"],
      componentType: DocumentEntityType.asset,
    },
    FINANCIALS_OTHER_FINANCIALS: {
      path: ["allAssets"],
      componentType: DocumentEntityType.asset,
    },
  },
  INCOMES: {
    INCOMES: {
      path: ["income", "employments"],
      componentType: DocumentEntityType.incomeEmployment,
    },
    INCOMES_EMPLOYMENTS: {
      path: ["income", "employments"],
      componentType: DocumentEntityType.incomeEmployment,
    },
    INCOMES_OTHER_INCOMES: {
      path: ["income", "others"],
      componentType: DocumentEntityType.incomeOther,
    },
    INCOMES_PENSIONS: {
      path: ["income", "employments"],
      componentType: DocumentEntityType.incomePension,
    },
    INCOMES_SELF_EMPLOYED: {
      path: ["income", "employments"],
      componentType: DocumentEntityType.incomeEmployment,
    },
  },
  PROPERTIES: {
    PROPERTIES: {
      path: ["properties"],
      componentType: DocumentEntityType.property,
    },
    PROPERTIES_OTHER_PROPERTY: {
      path: ["properties"],
      componentType: DocumentEntityType.property,
    },
    PROPERTIES_OTHER_PROPERTIES: {
      path: ["properties"],
      componentType: DocumentEntityType.property,
    },
    PROPERTIES_SUBJECT_PROPERTY: {
      path: ["property"],
      componentType: DocumentEntityType.property,
    },
  },
  // We dont have entity ID for these categories, they will fall back to a fack path to return undefined on the lookup
  // IDENTIFICATION: {},
  // FINAL_DOCUMENTS: {},
  // LOW_RATE_GUARANTEE: {},
  // OTHER: {},
};

export const getDocumentPathInfo = (
  document: GetDocumentsResponse
): { path: (string | number)[]; componentType: DocumentEntityType } => {
  // see `categoryPathMapping` rest of category and subcategory will fallback to undefined entity
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const pathInfo = categoryPathMapping?.[document.category]?.[
    document.subCategory
  ] || {
    path: ["~~CAT AND SUB CAT WITH NO ENTITY SHOULD RETURN UNDEFINED~~"],
    componentType: "",
  };

  return pathInfo;
};

export const getDocumentEntity = (
  document: GetDocumentsResponse,
  application: Application
) => {
  const applicantPath = ["applicants", document.applicantId];
  const pathInfo = getDocumentPathInfo(document);
  const pathArray: (string | number)[] = [];

  if (document.subCategory !== "PROPERTIES_SUBJECT_PROPERTY") {
    pathArray.push(...applicantPath);
  }

  pathArray.push(...pathInfo.path);

  const entity = getByPath(pathArray, application);

  // is the entity an array?
  if (Array.isArray(entity)) {
    // find the entity in the array
    const entityInArray = entity.find((e) => e.id === document.entityId);
    return { entity: entityInArray, pathInfo };
  }

  return { entity, pathInfo };
};

type DocumentEntity =
  | RegisteredAddress
  | Asset
  | IncomeEmployment
  | IncomeOther
  | OtherProperty;
/**
 *
 * @param {[string | number]} path - path array into the object
 * @param obj - any object to look into
 * @returns the value at the path or undefined
 */
const getByPath = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  path: any[],
  obj: Application
): DocumentEntity | DocumentEntity[] | undefined => {
  return path.reduce((p, c) => p?.[c], obj);
};

export const getDocumentCompositeKey = ({
  applicationId,
  applicantId,
  documentType,
  year = 0,
  entityId = 0,
}: Pick<
  GetDocumentsResponse,
  "applicationId" | "applicantId" | "documentType" | "year" | "entityId"
>) => `${applicationId}-${applicantId}-${documentType}-${year}-${entityId}`;
