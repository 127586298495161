import { api } from "@shared/api/client";
import {
  Application,
  ApplicationsPayload,
  ApplicationsResponse,
  Account,
  CommunicationPreferences,
  DeleteAccountFormData,
  DownPayment,
  AssignedAdvisor,
} from "@shared/constants";

export const getAccount = async () => {
  const { data } = await api.get<Account>("account");
  return data;
};

export const updateAccount = async (params: Account) => {
  const { data } = await api.put<Account>("account", params);
  return data;
};

export const getCommunicationPreferences = async () => {
  const { data } = await api.get<CommunicationPreferences>(
    "/account/communications/preferences"
  );
  return data;
};

export const updateCommunicationPreferences = async (
  params: CommunicationPreferences
) => {
  const { data } = await api.put<CommunicationPreferences>(
    "/account/communications/preferences",
    params
  );
  return data;
};

export const deleteAccount = async (feedback: DeleteAccountFormData) => {
  const { data } = await api.post("/account/deletion", feedback);
  return data;
};

export const getApplication = async (id: number) => {
  const { data } = await api.get<Application>(`applications/${id}`);
  return data;
};

export const getApplicationsByApplicantId = async () => {
  const { data } = await api.get<Application[]>(`applications/byapplicantid`);
  // allways sort by created date ASC to keep a consistent order in frontend
  return data
    ? data.sort(
        (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()
      )
    : [];
};

export const getAssignedRepresentative = async (applicationId: number) => {
  const { data } = await api.get<AssignedAdvisor>(
    `applications/${applicationId}/assigned-representative`
  );

  return data;
};

export const getApplications = async (payload: ApplicationsPayload) => {
  const { data } = await api.post<ApplicationsResponse>(
    "applications",
    payload
  );

  return data;
};

export const getApplicationsExternalBroker = async (
  payload: ApplicationsPayload
) => {
  const { data } = await api.post<ApplicationsResponse>(
    "external-brokerage/applications",
    payload
  );

  return data;
};

export const getApplicationsSearchResults = async (
  payload: ApplicationsPayload
) => {
  const { data } = await api.post<ApplicationsResponse>(
    "applications/minified",
    payload
  );

  return data;
};

export const getApplicationsSearchExternalBrokerResults = async (
  payload: ApplicationsPayload
) => {
  const { data } = await api.post<ApplicationsResponse>(
    "external-brokerage/applications/minified",
    payload
  );

  return data;
};

export const getDownPayment = async (applicationId: number) => {
  const { data } = await api.get<DownPayment>(
    `applications/${applicationId}/downpayment`
  );

  return data;
};
