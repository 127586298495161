import { ApplicationState, ApplicationTypeEnum } from "@shared/constants";
import {
  getMinimumDownPayment,
  getTargetPropertyValue,
  isTargetPropertyOnlyRental,
} from "@shared/utils";

import type { Applicant, Application, TargetProperty } from "@shared/constants";

export const isApplicationComplete = ({
  applicationState,
  locked,
}: Application) =>
  Boolean(applicationState !== ApplicationState.Created && locked);

/**
 * Returns the total down payment amount from all applicants
 * @param {Applicant[]} applicants the list of applicants
 */
export const getDownPaymentAmount = (
  applicants: Record<number, Applicant>
): number => {
  if (!applicants) return 0;

  // TODO: there is a potential bug here - we don't check if the asset is marked as willBeUsedForDownPayment
  return Object.values(applicants).reduce((downpayment, applicant) => {
    return (
      downpayment +
      (applicant.allAssets
        ? applicant.allAssets.reduce((applicantDownpayment, asset) => {
            if ("amountUsedForDownPayment" in asset) {
              return applicantDownpayment + asset.amountUsedForDownPayment;
            }

            return applicantDownpayment;
          }, 0)
        : 0)
    );
  }, 0);
};

/**
 * Validates that the total downpayment amount is sufficient based
 * on the value of the property and its rental status.
 *
 * For rental properties, the downpayment must be at least 20% of the property value.
 *
 * For properties valued at more than $1,000,000, the downpayment must be at least 20% of the property value.
 *
 * For properties valued between $500,000 and $1,000,000, the downpayment must be at least
 * 5% of the first $500,000 of the property value AND 10% of the portion of the property value above $500,000.
 *
 * For properties valued below $500,000, the downpayment must be at least 5% of the property value.
 *
 * @param propertyValue the value of the property
 * @param downPayment the total downpayment amount
 * @param isRentalProperty whether the property is for rental or not
 */
export const isViableDownPayment = (
  propertyValue: number,
  downPayment: number,
  isRentalProperty: boolean
): boolean => {
  if (!propertyValue || !downPayment) return false;

  const minimumDownPayment = getMinimumDownPayment(
    propertyValue,
    isRentalProperty
  );

  return downPayment >= minimumDownPayment;
};

export const isDownPaymentValid = (
  applicationType: ApplicationTypeEnum,
  downPayment: number,
  targetProperty?: TargetProperty
) => {
  if (!targetProperty) return false;
  if (applicationType !== ApplicationTypeEnum.NEW) return true;

  const isRental = isTargetPropertyOnlyRental(targetProperty);
  const propertyValue = getTargetPropertyValue(applicationType, targetProperty);

  const isViable = isViableDownPayment(propertyValue, downPayment, isRental);

  return isViable;
};
